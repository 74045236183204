import {extendTheme} from "@chakra-ui/react"

const ChakraTheme = extendTheme({
    styles: {
        global: {
            body: {
                bg: "#f8f8f8"
            }
        }
    },
    fonts: {
        heading: "Inter, sans-serif",
        body: "Inter, sans-serif",
    },
    colors: {
        secondary: '#8dcd58',
        secondaryLighter: '#e0e3eb',
        brand: '#1db9cc',
        orange: '#eb731a',
        cta: '#eb731a'
    },
    components: {
        Progress: {
            baseStyle: {
                filledTrack: {
                    bg: '#8dcd58'
                }
            }
        }
    }
})

export default ChakraTheme;
