import "./Grenosan.scss";
import '@fontsource/inter/700.css';
import '@fontsource/inter/400.css'

function GrenosanTemplate(props) {
    return (
        <div className={"page grenosan"}>
            {props.children}
        </div>
    );
}

export default GrenosanTemplate;
