import './Products.scss';
import package1 from '../../assets/products/package-1.png';
import package2 from '../../assets/products/package-2.png';
import package3 from '../../assets/products/package-3.png';
import {Box, Heading, Image, List, ListIcon, ListItem, Text} from "@chakra-ui/react";
import {CheckIcon} from "@chakra-ui/icons";
import Logo from '../../assets/home/logo.svg';
import GermanPackages from '../../lang/de/products.json';
import DutchPackages from '../../lang/nl/products.json';
import FrenchPackages from '../../lang/fr/products.json';
import EnglishPackages from '../../lang/en/products.json';
import SwissPackages from '../../lang/ch/products.json';
import {FormattedMessage} from "react-intl";

function Products(props) {
    const images = [];
    images['package1'] = package1;
    images['package2'] = package2;
    images['package3'] = package3;

    const whichPackage = () => {
        switch (true) {
            case props.totalDays >= 1 && props.totalDays <= 29:
                return 'package1';
            case props.totalDays >= 30 && props.totalDays <= 44:
                return 'package2';
            case props.totalDays > 44:
                return 'package3';
            default:
                return 'package3';
        }
    };

    const getImage = (product) => {
        return images[product];
    };

    let packages = [];

    switch (props.country) {
        case 'nl':
            packages = DutchPackages;
            break;
        case 'fr':
            packages = FrenchPackages;
            break;
        case 'en-gb':
            packages = EnglishPackages;
            break;
        case 'de-ch':
            packages = SwissPackages;
            break;
        default:
            packages = GermanPackages;
    }

    let product = packages[whichPackage()];

    return (
        <Box background={'#e6ecce'} p={5} borderRadius={'lg'} textAlign={'center'}>
            <Box display={'flex'}
                 alignItems={'center'}
                 fontSize={'xl'}
                 fontWeight={'bold'}
                 justifyContent={'center'}>
                <Image mr={2} height={10} src={Logo} display={'inline-block'} />
            </Box>

            <Heading as="h3" size="xl" mt={3}>
                {product.subheading}
            </Heading>

            <img className={'product-image'} src={getImage(whichPackage())} alt=""/>

            <Heading as="h4" size="md" mb={3}>{product.heading}</Heading>

            <List spacing={3}>
                <ListItem>
                    <ListIcon mb={0.5} as={CheckIcon} color='black'/>
                    <FormattedMessage id="product.fact1"/>
                </ListItem>
                <ListItem>
                    <ListIcon mb={0.5} as={CheckIcon} color='black'/>
                    <FormattedMessage id="product.fact2"/>
                </ListItem>
                <ListItem>
                    <ListIcon mb={0.5} as={CheckIcon} color='black'/>
                    <FormattedMessage id="product.fact3"/>
                </ListItem>
                <ListItem>
                    <ListIcon mb={0.5} as={CheckIcon} color='black'/>
                    <FormattedMessage id="product.fact4"/>
                </ListItem>
            </List>

            <Box my={[3,5]}>
                <Heading as="h3" size="xl">{product.price}</Heading>
                <Text fontSize="14">
                    <FormattedMessage id="product.bottlePrice"/>
                </Text>
            </Box>

            <Text fontSize={['xs', 'sm']}>{product.totalPrice}</Text>
        </Box>
    )
}

export default Products;